export const LOGIN_USER = 'login_user';
export const REGISTER_USER = 'register_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const UPDATE_USER = 'update_user';
export const ADMIN_MENU = 'admin_menu';
export const CONFIG_SET = 'config_set';
export const REFRESH_USER = 'refresh_user';

export const GTW = 'gtw';
export const GTW_STATUS = 'gtw_status';
